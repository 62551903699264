import axios from 'axios';
import {Auth} from "aws-amplify";
import Cookies from 'js-cookie';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,//process.env.REACT_APP_API_ENDPOINT, // our API base URL
});


export const getToken = async () => {
    try {
        return localStorage.getItem("authToken");
    } catch {
        return null;
    }
};


//user.attributes['custom:central_user_id']
//'https://deliveryiotdevapi.rehrig.com
// Request interceptor for adding the bearer token
api.interceptors.request.use(
    async(config) => {
        const token = localStorage.getItem("authToken"); // Assuming you store the token in localStorage
       // const session = await Auth.currentSession();
        //const loggedInUserIDToken = session.accessToken.jwtToken;
       // if (loggedInUserIDToken) {
            config.headers.Authorization = await getToken();//`Bearer ${loggedInUserIDToken}`;
            //console.log("config.headers.Authorization....",config.headers.Authorization)
            //config.headers['x-api-key'] = 'jPJoEqjzqC9NjMpWroPPH8G0vWQTV4Kcazb5LXMp';
            //config.headers.Authorization = `Bearer ${loggedInUserIDToken}`;
       // }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);



// API endpoints
export const getQuicksightData = (qUrl,fType,cId,dId) => {
    try {
        return api.get(`/VorAdminSupportPortal/quick_sight_data?s3_url=${qUrl}&data_type=${fType}&customer_id=${cId}&dc_id=${dId}`);
    }catch (ex){
        console.log(ex.message);
    }

};

export const getQuicksightDataDetails = (picklist,fType,cId,dId) => {
    try {
        return api.get(`/VorAdminSupportPortal/quick_sight_data?picklist=${picklist}&data_type=${fType}&customer_id=${cId}&dc_id=${dId}`);
    }catch (ex){
        console.log(ex.message);
    }

};





// Export the api instance
export default api;
